@tailwind base;
@tailwind components;
@tailwind utilities;

.backg{
  background-color:hsla(225,39%,18%,1);
  background-image:
  radial-gradient(at 58% 55%, hsla(30,67%,53%,0.18) 0px, transparent 50%),
  radial-gradient(at 95% 94%, hsla(223,69%,25%,0.77) 0px, transparent 50%),
  radial-gradient(at 0% 0%, hsla(209,86%,61%,0.44) 0px, transparent 50%);
}

.btn {
  background: linear-gradient(
    92.23deg,
    #D98236 21.43%,
    #23B7D9 117.04%
  );
  box-shadow: 0px 4.42184px 107.23px rgba(255, 176, 86, 0.51);
}

.btn:hover {
  background: linear-gradient(
    92.23deg,
    #23B7D9 21.43%,
    #D98236 117.04%
  );
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #23B7D9 21.43%,
    #D98236 117.04%
  );
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#2B76D9] to-[#23B7D9] font-bold font-primary hover:from-[#23B7D9] hover:to-[#2B76D9];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}
